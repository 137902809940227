import { utils } from '../utils';

class fullscreenHelper {
  //constructor() {}
  getFullscreenIdxFromParams() {
    const fullscreenIdx = utils.parseGetParam('fullscreenIdx');
    if (fullscreenIdx) {
      return parseInt(fullscreenIdx);
    }
  }

  getDirectFullscreenFromParams() {
    //check
    const fullscreenParam = utils.parseGetParam('directFullscreen');
    if (fullscreenParam) {
      const res = fullscreenParam.split('|');
      return (
        res[0] &&
        res[1] && {
          compId: res[0],
          itemId: res[1],
        }
      );
    }
  }
  directFullscreenItemIdxIfAvailable(props, items = props.items, itemId) {
    const directItemId =
      itemId || (props.directFullscreenItem && props.directFullscreenItem.id);
    const idx = items.findIndex(item => item.itemId === directItemId);
    if (idx >= 0) {
      return idx;
    } else {
      return false;
    }
  }
  getDirectFullscreenIdx(props) {
    let idx;
    const directFullscrennParams = this.getDirectFullscreenFromParams();
    if (directFullscrennParams && directFullscrennParams.compId === props.id) {
      if (props.wixCodeItems) {
        idx = this.directFullscreenItemIdxIfAvailable(
          props,
          props.wixCodeItems,
          directFullscrennParams.itemId,
        );
        if (idx >= 0) {
          return idx;
        } else {
          return -1;
        }
      } else {
        this.processDirectFullscreenItemId(
          props,
          directFullscrennParams.itemId,
        );
      }
    } else {
      //trying to get the old fullscreenIdx param - just for use in debugging
      const fullscreenIdx = this.getFullscreenIdxFromParams();
      return fullscreenIdx >= 0 ? fullscreenIdx : -1;
    }
  }

  processDirectFullscreenItemId(props, itemId) {
    typeof props.getAndSetDirectFullscreenItem === 'function' &&
      props.getAndSetDirectFullscreenItem(itemId);
    //this.setMetaTagsForItemIfNeeded(item); - this could be a viewerScript run function (no need to run it from here. less postMessages) All in due (seo) time
  }

  setMetaTagsForItemIfNeeded(props, item) {
    const isInSEO = props.isInSEO;
    if (item && isInSEO) {
      const type = 'image'; // TODO - connect the real deal. could be galleryItem.js L1035 - and understand how it matters? maybe i dont need this one.
      const dto = item.metaData;
      props.setMetaTags && props.setMetaTags(type, dto);
    }
  }
}
export default new fullscreenHelper();
